import React from "react";

import logoFooter from "../../images/logos/logo-white.svg"
import iconTwitter from "../../images/icons/icon-twitter.svg"
import iconLinkedIn from "../../images/icons/icon-linkedin.svg"
import iconEnvelope from "../../images/icons/icon-envelope.svg"

const Footer = () => (
  <footer className="main-footer">
    <div className="top-bar container">
      <div className="row">
        <div className="brand-links col-xs-12 col-sm-12 col-md-6"> 
          <a className="navbar-brand" href="/">
              <img src={logoFooter} alt="Compute Software Logo" className="logo" width="220" height="33" />
          </a>
          <ul className="social-icons">
            <li>
              <a className="linkedin" href="https://www.linkedin.com/company/computesoftware">
                <img src={iconLinkedIn} alt="Mail Icon" />
              </a>
            </li>
            <li>
              <a className="twitter" href="https://twitter.com/ComputeInc">
                <img src={iconTwitter} alt="Mail Icon" />  
              </a>
            </li>
            <li>
              <a className="email" href="mailto:contact@computesoftware.com">
                <img src={iconEnvelope} alt="Mail Icon" />          
              </a>
            </li> 
          </ul>
        </div>
        <div className="company-links col-xs-6 col-sm-4 col-md-2">
          <h6>Company</h6>
          <ul className="footer-links">
            <li><a href="/company/">About</a></li>
            <li><a href="/careers/">Careers</a></li>
            <li><a href="/contact">Contact Us</a></li>
          </ul>
        </div>
        <div className="product-links col-xs-6 col-sm-4 col-md-2">
          <h6>Product</h6>
          <ul className="footer-links">
            <li><a href="/platform">Platform</a></li>
          </ul>
        </div>
        <div className="resource-links col-xs-12 col-sm-4 col-md-2">
          <h6>Resources</h6>
          <ul className="footer-links">
            <li><a href="/documentation/overview">Documentation</a></li>
            <li><a href="http://updates.computesoftware.com/" target="_blank" rel="noreferrer">Product Updates</a></li>
            <li><a href="/contact?form=support">Support</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div className="bottom-bar container">
      <div className="row">
        <div className="col-md-3 col-sm-12 col-xs-12">
          <p className="copyright-text">&copy;&nbsp;2021 Compute Software, Inc.</p>
        </div>
        <div className="col-md-3 col-sm-12 col-xs-12"> 
          <ul className="legal-links nav navbar-nav navbar-center">
        <li><a href="/privacy-policy">Privacy Policy</a></li>
        <li><a href="/terms/">Terms</a></li>
      </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;