import React from "react";

import logoHeader from "../../images/logos/logo-color.svg"

export default class Header extends React.Component {
  render() {
    return (
      <header className="header">
        <nav id="main-navigation" className="main-navigation navbar navbar-expand-lg fixed-top" role="navigation">
            <div className="container">
              <div className="navbar-header">
                  <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#main-navbar" aria-expanded="false" aria-controls="main-navbar">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar top-bar"></span>
                    <span className="icon-bar middle-bar"></span>
                    <span className="icon-bar bottom-bar"></span>
                  </button>
                  <a className="navbar-brand" href="/">
                    <img src={logoHeader} alt="Compute Software Logo" className="logo" width="257" height="38" />
                  </a>
                </div>
            <div id="main-navbar" className="navbar-collapse collapse">
              <div className="navbar-left">
                <ul className="nav navbar-nav navbar-center">
                  <li className="nav-item"><a href="/platform">Platform</a></li>
                  <li className="nav-item dropdown dropdown-submenu">
                    <a href="/resources" className="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-delay="500" data-close-others="true" role="button" aria-haspopup="true" aria-expanded="false">
                      Resources <span className="arrow rotate"><svg width="24" height="24" viewBox="0 0 24 24"><path fill="#565656" d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></span>
                    </a>
                              <ul className="sub-menu dropdown-menu">
                          <li><a href="/documentation/overview">Documentation</a></li>
                          <li><a href="http://updates.computesoftware.com/" target="_blank" rel="noreferrer">Product Updates</a></li>
                          <li><a href="/contact?form=support">Support</a></li>
                        </ul>
                  </li>
                  <li className="nav-item dropdown dropdown-submenu">
                              <a href="/about" className="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-delay="500" data-close-others="true" role="button" aria-haspopup="true" aria-expanded="false">
                                Company <span className="arrow rotate"><svg width="24" height="24" viewBox="0 0 24 24"><path fill="#565656" d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></span>
                              </a>
                              <ul className="sub-menu dropdown-menu">
                          <li><a href="/company/">About</a></li>
                          <li><a href="/careers/">Careers</a></li>
                          <li><a href="/contact">Contact Us</a></li>
                        </ul>
                  </li>
                </ul>
              </div>
              <div className="navbar-right">
                <ul className="nav navbar-nav">
                  <li className="log-in"><a href="https://app.computesoftware.com/">Log In</a></li>
                        <li className="request-demo">
                          <a className="btn hidden-xs" href="/request-demo">Request a Demo</a>
                          <a className="hidden-lg hidden-md hidden-sm" href="/request-demo">Request a Demo</a>
                        </li>
                      </ul>
                  </div>
            </div>
          </div>
        </nav>
      </header>
    )
  }
};